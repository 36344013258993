<template>
    <div id="app">
        <router-view />
        <van-tabbar v-model="active" route @change="onChange">
            <van-tabbar-item replace to="/homeview" icon="home-o">更多项目</van-tabbar-item>
            <van-tabbar-item replace to="/jiema" icon="search">短信接码</van-tabbar-item>
            <van-tabbar-item replace to="/myinfo" icon="friends-o">我的</van-tabbar-item>
        </van-tabbar>
    </div>
</template>
<script>
export default {
    data() {
        return {
            active: 0,
        };
    },
    methods: {
        onChange() {
            // console.log(i, a);
        },
    },
};
</script>
<style lang="less" scoped>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    width: 375px;
    margin: 0 auto;
    &/deep/ .van-tabbar {
        width: 375px;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
    }
}
nav {
    padding: 30px;
}

nav a {
    font-weight: bold;
    color: #2c3e50;
}

nav a.router-link-exact-active {
    color: #42b983;
}
</style>
