import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        redirect: "/homeview",
        meta: {
            requireAuth: true,
        },
    },
    {
        path: "/homeview",
        name: "homeview",
        component: () => import("../views/HomeView.vue"),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: "/jiema",
        name: "jiema",
        component: () => import("../views/JieMa.vue"),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: "/myinfo",
        name: "myinfo",
        component: () => import("../views/MyInfo.vue"),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: "/login",
        name: "login",
        component: () => import("../views/LoginView.vue"),
    },
    {
        path: "/balance",
        name: "balance",
        component: () => import("../views/MyBalance.vue"),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: "/useway",
        name: "useway",
        component: () => import("../views/UseWay.vue"),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: "/balanceDetail",
        name: "balanceDetail",
        component: () => import("../views/BalanceDetaile.vue"),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: "/epwd",
        name: "epwd",
        component: () => import("../views/EditPwd.vue"),
        meta: {
            requireAuth: true,
        },
    },
];

const router = new VueRouter({
    routes,
});

router.beforeEach((to, from, next) => {
    // 判断页面是否需要检测登录状态
    if (to.meta.requireAuth) {
        if (!localStorage.getItem("token")) {
            next({
                path: "/login",
            });
        } else {
            next();
        }
    } else {
        next();
    }
    // 访问login页面时，若已登陆，则直接跳转首页
    if (to.name == "login") {
        if (localStorage.getItem("token")) {
            router.push("/index");
        }
    }
});

export default router;
